import React, { Component } from 'react';
import { Button } from 'reactstrap';

class WidgetCloseButton extends Component {
    render() {
        return (
            <Button color="default" className="btn-circle widgetclosebutton" onClick={this.closeWidget}>
                <i className="fa fa-times" aria-hidden></i>
                <span className="visually-hidden">
                    Sluiten
                </span>
            </Button>
        );
    }

    closeWidget() {
        window.parent.postMessage('reservi-widget-close', '*');
    }
}

export default WidgetCloseButton;

