import { TOGGLE_SETTING, CHANGE_SETTING, CHANGE_NOTIFICATION, RESET_NOTIFICATIONS, CHANGE_POPUP, RESET_POPUPS } from './settings.actions';
import { CONTACT_LOGGED_OUT } from '../contact/contact.actions';
import routes from '../../routing/routes';

const initialSettings = {
    /* Nr of agendas next to each other in group overview */
    defaultLanguage: 'nl',
    language: 'nl',
    nrAgendasRowGroupOverview: 3,
    entrance: null,
    overview: {
        route: routes.notfound,
        path: ''
    },
    appointmentTypeInput: null,
    afterLoginUrl: null,
    contactList: {},
    decisionTreeResult: null,
    showSidebarCalandar: false,
    showReturnTypeOverviewButton: false,
    notifications: {},
    popupsShown: [],
    autoRequestLink: null,
    requestParams: {
        date: null,
        type: null,
        rangeFrom: null,
        rangeTill: null
    },
    sessionId: null,
    extraParam: null,
    guid: null,
    widget: {
        type: null,
        color: null
    }
};

const settingsReducer = (state = initialSettings, action) => {
    switch (action.type) {
        case TOGGLE_SETTING:
            return {
                ...state,
                [action.name]: !state[action.name]
            }
        case CHANGE_SETTING:
            return {
                ...state,
                [action.name]: action.value
            };
        case CONTACT_LOGGED_OUT:
            return {
                ...initialSettings,
                popupsShown: state.popupsShown
            };
        case CHANGE_NOTIFICATION:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    [action.id]: action.value
                }
            }
        case RESET_NOTIFICATIONS:
            return {
                ...state,
                notifications: {}
            }
        case CHANGE_POPUP:
            return {
                ...state,
                popupsShown: action.value
            }
        case RESET_POPUPS:
            return {
                ...state,
                popupsShown: []
            }
        default:
            return state;
    }
}

export default settingsReducer;