import React, { Component } from 'react';
import { t } from 'i18next';

class Footer extends Component {

    render() {
        //const year = new Date().getFullYear()
        return (
            <footer className="footer-container">
                {/*<div className="colortest colortest-primary"></div>
                <div className="colortest colortest-primary-light"></div>
                <div className="colortest colortest-primary-lighter"></div>
                <div className="colortest colortest-primary-lightest"></div>
                <div className="colortest colortest-primary-dark"></div>*/}
                <span>
                    &copy;
                    {' '}
                    <a href="https://www.reservi.be" target="_blank" rel="noopener noreferrer">
                        <img src="/images/reservi-logo.svg" className="footer-logo" height="16" alt="Logo Reservi" />
                    </a>
                    {' - '}
                    <a href={t('footer:general-conditions.link')} target="_blank" rel="noopener noreferrer">
                        {t('footer:general-conditions.text')}
                    </a>
                </span>
            </footer>
        );
    }

}

export default Footer;
